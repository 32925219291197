@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
    justify-content: center;
    align-items: center;
}

body {
  background: #ffffff;
  border: 10px solid black;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  
}

:root{
    --swiper-navigation-color: white;
    --swiper-theme-color:white;
}

.sliderfirstimage{

}
.swiper {
    display: flex;
    align-items: center;
    justify-content: center;
  width: 80%;
  height: 80%;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
    padding-bottom: 100px;

}

.videosflex
{
  display: flex;
  flex-direction: column;

}
.title{
  display: flex;
  justify-content: center;
  align-items: center;
}

.title h1{
  font-size: large;
  font-style: 900;
  padding: 20px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  display: flex !important;
justify-content: center;
align-items: center;
}

.bulletclass{
    padding: 5px;
    border-radius: 100%;
    background-color: rgb(105, 105, 243);
    margin-top: 10px;
    margin: 4px;
    width: 70px !important;
}
.swiper-slide img {
  display: block;
  justify-content: center;
  align-items: center;
}
