.modalmain{
    width: 250px;
    height: 50px;
    background-color: #5a698f;
    box-shadow: 2px 2px 2px gray;
    border-radius: 10px;
    position: fixed;
    top: 5%;
    left: 40%;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 5px;
    display: flex;
}

.slide-in-elliptic-top-fwd {
	animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-elliptic-top-fwd {
    0% {
      transform: translateY(-600px) rotateX(-30deg) scale(0);
      transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      transform: translateY(0) rotateX(0) scale(1);
      transform-origin: 50% 1400px;
      opacity: 1;
    }
  }
  
.modalinner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.modalinner button{
    background-color: transparent;
    outline: none;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.modalmain p{
    text-align: center;
    color: white;
}

.maincontent{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: flex-end;
    margin-left: 9rem;
    position: absolute;
    margin-top: 2rem;
    z-index: 200;
}

.searchbarmain{
    display: flex;
    flex-direction: row;
    flex: 2;
}

.searchbarmain input{
    border: none;
    outline: none;
    background-color: transparent;
    width: 300px;
    color: white;
    flex: 2;
}

.moviescard{
    display: flex;
    flex-direction: column;
    color: white;
    overflow-y: auto;
}

.movies{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 100%;
}
@media screen and (max-width:550px) {
    .maincontent{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: start;
        justify-content: center;
        margin: 1rem;
    }

    .modalmain{
        width: 200px;
        height: 30px;
        background-color: white;
        border-radius: 10px;
        position: fixed;
        top: 5%;
        left: 18%;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        padding: 5px;
        display: flex;
        background-color: #5a698f;
    }

    .modalmain p{
        text-align: center;
        font-size: 10px;
        color: white;
    }
}


.smallcardmovies{
    height: 250px;
    width: 300px;
    padding: 10px;
    position: relative;
    margin-top: 10px;
}

.bookmarkstyle{
    position: absolute;
    z-index: 100;
    top:7%;
    left: 5%;
    width: 20px;
    border: none;
    outline: none;
    background-color: rgba(36, 36, 36, 0.5);
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}

.smallcardmovies img{
    width: 100%;
    border-radius:  1rem;
}

.smallcardmovies p{
    margin: 0;
    
}

.smallheading{
    display: flex;
    margin: 0;
}
.smallheading p{
    margin-right: 9px;
}

.smallcardtitle{
    font-size: 20px;
    padding: 0;
}

@media screen and (max-width: 550px) {
    .smallcardmovies{
        height: 100px;
        width: 150px;
        padding: 6px;
        margin-top: 2rem;
    }

    .bookmarkstyle{
        position: absolute;
        z-index: 100;
        top:13%;
        left: 10%;
        width: 10px;
        border: none;
        outline: none;
        background-color: rgba(36, 36, 36, 0.5);
        border-radius: 10px;
        padding: 2px;
        cursor: pointer;
    }
    .smallcardtitle{
        font-size: 12px;
        padding: 0;
    }
    .smallheading p{
        font-size: 10px;
    }
}