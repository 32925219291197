@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

.backgroundmain{
    background-color:#1f3641;
    min-height: 100vh;
}
.maincard{
    top: 50%;
    left: 50%;
    bottom: 0;
    right: 0;
    width: 600px;
    height: 600px;
    position: fixed;
    transform: translate(-50%,-50%);
    border: none;
    outline: none;
    box-shadow: 0 0 0;
    background-color: #1f3641;
}

.insidecard{
    height: 80%;
    width: 50%;
    top: 50%;
    left: 50%;
    bottom: 0;
    right: 0;
    position: relative;
    transform: translate(-50%,-50%);
}

.textmodalflex{
    
    align-items: center;
    justify-content: center;
    font-family: "Roboto Mono", monospace;
    color: white;
    text-align: center;
    display: flex;
}

.textinmodal{
    align-items: center;
    text-align: center;
    justify-content: center;
}
.modalflex{
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 5px;
    align-items: center;
    justify-content: center;
}
.modaldisp{
    top: 40%;
    left: 50%;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 50px;
    position: fixed;
    transform: translate(-50%,-50%);
    border: 1px solid black;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 5px 0px black;
    background-color: #1f3641;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 350px;
    z-index: 1000;

}

.insideflex{
    display: flex;
    flex-direction: column;
}

.boxesgrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 7px;
}

.xo{
    width: 100%;
    height: 100%;
}

.heading{
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    gap: 40px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.headingflex{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto Mono", monospace;
}

.heading1flex{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 0px 2px 0px black;
    background-color:  #dbe8ed;
    padding: 3px;
    width: 50%;
}

.heading1flex:hover{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 0px 2px 0px black;
    background-color:  #dbe8ed;
    padding: 3px;
    width: 50%;
    transform: scale(105%);
}

.heading1{
    width: 40px;
    height: 18px;
}



.heading2{
    width: 18px;
    height: 18px;
}

.heading3{
    width: 18px;
    height: 18px;
}

.restartbutton{
    border: 1px solid black;
    border-radius: 10px;
    background-color: #65e9e4;
    width: 50px;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    box-shadow: 0px 3px 0px black;
}

.headingflex{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: white;
    margin: 0;
}
.headingflex p {
    margin: 0;
}
.footer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
    align-items: center;
    justify-content: center;
    align-self: center;
    gap: 15px;
    margin-top: 15px;

}

.footerbox{
    display: grid;
    width: 80px;
    height: 50px;
    background-color: #65e9e4;
    grid-template-rows: 1fr 1fr;
    border-radius: 10px;
    font-size: 10px;
    text-align: center;
}

.footerboxheading{
    padding: 5px;
    font-weight: 700;
    margin: 0;
    font-family: "Roboto Mono", monospace;
    color: black;
}

.footerboxscore{
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    color: black;
}
.seconditem{
    position: absolute;
    right: 0;
}

.btn{
    border: 1px solid black;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 5px 0px black;
    background-color: #1f3641;
    align-items: center;
    justify-content: center;
    height: 100px;
    
}

.insidebtn{
    border: 1px solid black;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100px;
    box-shadow: 0px 5px 0px black;
    background-color: #1f3641;
}