:root {
    --red: #fc4747;
    --pureWhite: #fff;
    --darkBlue: #10141e;
    --semiDarkBlue: #161d2f;
    --greyishBlue: #5a698f;
}



.navcontainer{
    position: absolute;
    background-color: var(--semiDarkBlue);
    position: fixed;
    display: flex;
    border: 2px solid black;
    flex-direction: column;
    padding: 1.5rem;
    margin: 2rem;
    justify-content: space-between;
    height: 80%;
    border-radius: 1rem;
    margin-top: 2rem;
}

.navflexcolumn{
    display: flex;
    flex-direction: column;
}

.navmidicons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.navmidicons img{
    margin-top: 1.5rem;
    cursor: pointer;
}

.navmidicons img:hover{
    margin-top: 1.5rem;
    cursor: pointer;
    scale: 130%;
}

.usericonmain{
    display: flex;
}
.usericonmain img{
    height: 30px;
    width: 30px;
    cursor: pointer;
}

@media screen and (max-width:550px) {
    
    .navcontainer{
        display: flex;
        flex-direction: row;
        border: 2px solid black;
        padding: 1rem;
        width: 80%;
        position: relative;
        margin: 1rem;
        height: 5%;
        justify-content: space-between;
    }
    
    .navflexcolumn{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .navmidicons{
        display: flex;
        flex-direction: row;
    }

    .navmidicons img{
        margin: 0;
        padding: 10px;
    }

    .usericonmain{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .usericonmain img{
        height: 30px;
        width: 30px;
    }

}