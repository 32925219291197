@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root{
    --background: hsl(0, 0%, 100%);
    --text-color: black;
    --inside-text-color: white
}

[data-theme="dark"]{
    --background: hsl(222, 41%, 20%);
    --text-color: white;
    --inside-text-color: white
}

.cardmain{
    width: 700px;
    height: 500px;
    top: 50%;
    left: 50%;
    bottom: 0;
    right: 0;
    position: relative;
    transform: translate(-50%,50%);
    padding: 15px;
    font-family: "Space Mono", monospace;

}

.searchcard
{
    position: relative;
    border: 1px solid black;
    padding: 5px;
    align-items: center;
    justify-content: center;
    height: 8%;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    background-color: var(--background);
    border-radius: 10px;
}

.lastrowsize{
    height: 20px;
    margin-right: 8px;
}

.searchicon{
    margin-left: 10px;
}

.lastrowhorizontalflex{
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.searchinput{
    width: 90%;
    background:transparent;
    color: var(--text-color);
    border: none;
    font-size: 17px;
    font-family: "Space Mono", monospace;
}

.searchbtn{
    background-color: hsl(212, 100%, 50%);
    border-radius: 10px;
    right: 0;
    border: 1px solid hsl(212, 100%, 50%);
    padding: 1px;
    font-weight: 500;
    color: white;
    font-size: 12px;
}

.infoflex{
    display: flex;
    flex-direction: column;
}

.devfinderheading
{
    color: var(--text-color);
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 10%;
    font-weight: 900;
    font-size: 18px;
}

.themecontainer{
    display: flex;
    position: absolute;
    right: 0;  
    align-items: center;
    justify-content: center;
}

.themeimg
{
    height: 20px;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}
.devfinder{
    position: absolute;
    left: 0;
    color: var(--text-color);
}
.devfinderheading p{
    color: var(--inside-text-color);
}

.ldtheme{ 
    font-size: 14px; 
}

.infocard{
    margin-top: 10px;
    height: 80%;
    border: 1px solid black;
    display: grid;
    grid-template-columns: 20% 80%;
    background-color: var(--background);
    border-radius: 10px;
}

.profilepic{
    width: 80%;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.profileimage{
    object-fit: contain;
    width: 100%;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
}

.infogridone{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
}

.usernametag
{
    color: var(--text-color);
}

.bio{
    color: var(--text-color);
    font-size: 11px;
}
.username{
    color: var(--text-color);
    font-size: 20px;
    font-weight: 900;
}

.joineditem{
    color: var(--text-color);
    font-weight: 400;
    font-size: 13px;
}

.insidecardgithub{
    border: 1px solid black;
    padding: 10px;
    margin-top: 10px;
    margin-right: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: hsl(220, 40%, 13%);
    color: var(--inside-text-color);
    border-radius: 10px;
    font-size: 14px;
}

.insidecardflex{
    display: flex;
    flex-direction: column;
}

.insidecardflex p{
    margin: 0;
    text-align: center;
}

.lastrowinside{
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: var(--text-color);
    font-size: 14px;
}

.lastrowflex{
    display: flex;
    flex-direction: column;
}
.joineddiv{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 480px) {

    .searchcard
    {
        position: relative;
        border: 1px solid black;
        padding: 5px;
        align-items: center;
        justify-content: center;
        height: 8%;
        display: grid;
        grid-template-columns: 10% 70% 20%;
        background-color: hsl(222, 41%, 20%);
        border-radius: 10px;
    }

    .cardmain{
        width: 350px;
        height: 500px;
        top: 50%;
        left: 50%;
        bottom: 0;
        right: 0;
        position: relative;
        border: 1px solid black;
        transform: translate(-50%,50%);
        padding: 15px;
        border-radius: 10px;
    }

    .searchicon{
        margin-left: 2px;
    }
    
    .searchinput{
        width: 90%;
        background:none;
        color: var(--inside-text-color);
    }
    
    .searchbtn{
        right: 0;
    }
    
    .devfinderheading
{
    color: var(--text-color);
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 10%;
    font-weight: 700;
    font-size: 14px;
}

.searchbtn{
    background-color: hsl(212, 100%, 50%);
    border-radius: 10px;
}

.bio{
    color: var(--text-color);
    font-size: 9px;
}

.joineddiv{
    display: flex;
    align-items: center;
    justify-content: center;
}
.username{
    color: var(--text-color);
    font-size: 14px;
    font-weight: 900;
}

.joineditem{
    color: var(--text-color);
    font-weight: 100;
    font-size: 10px;
}

.insidecardgithub{
    border: 1px solid black;
    margin-top: 10px;
    margin-right: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: hsl(220, 40%, 13%);
    color: var(--inside-text-color);
    border-radius: 10px;
    font-size: 11px;
}

.lastrowinside{
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    font-size: 11px;
}

.infogridone{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding-right: 10px;
    font-size: 10px;
}
}